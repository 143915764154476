import { Upload, UploadProps } from "antd"
import Box from "../box/box.component"
import FileUploadProps from "./file-upload.interfaces"
import { Icons } from "../icons"
import { Button } from "@components/controllers"
import InputLabel from "@components/controllers/input-label"

function FileUpload({
  title = "",
  onUploadingFile = () => {},
  onRemove,
  setFileList,
  fileList,
  maxCount = 1,
  columnView,
  buttonText,
  buttonSize = "large",
  multiple = false,
  disabled = false,
  rootClassName,
  className,
  ...restProps
}: FileUploadProps) {
  const props: UploadProps = {
    onRemove: (file) => {
      if (onRemove) {
        onRemove(file)
      } else {
        onUploadingFile(undefined)
      }
    },
    beforeUpload: (file) => {
      onUploadingFile(file)
      /**  @returns false To prevent unnecessary POST request */
      return false

      // return isMP4 || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (setFileList) setFileList(info.fileList)
    },
  }
  return (
    <Box className="flex items-center justify-between">
      <InputLabel label={title} columnView={columnView}>
        <Upload
          defaultFileList={[]}
          fileList={fileList}
          maxCount={maxCount}
          // type="select"
          multiple={multiple}
          {...props}
          className={rootClassName}
          // className="w-[250px] bg-slate-300"
          // className="w-[250px] bg-slate-400 flex flex-col items-center"
          {...restProps}
        >
          <Box className={className}>
            <Button
              size={buttonSize}
              variant="outlined"
              type="button"
              className={disabled ? "btn-disabled" : ""}
              disabled={disabled}
            >
              <Icons.Upload />
              {buttonText ? buttonText : "Upload"}
            </Button>
          </Box>
        </Upload>
      </InputLabel>
    </Box>
  )
}

export default FileUpload
