import Env from "@utils/env"
import networkService from "services/network/network.service"
import { ITextGeneratorReq } from "./videos-api.interfaces"

async function generateUsingText(params: ITextGeneratorReq) {
  const url = `${Env.get("REACT_APP_BASE_URL")}/ai/video/generate-from-text`

  const { text, userId, isLead, pictures, logo } = params
  if (!text || !userId || isLead === undefined)
    throw new Error("Not all params are set!")

  const formData = new FormData()
  formData.append("text", text)
  formData.append("userId", userId)
  formData.append("isLead", isLead.toString())
  if (pictures && pictures.length) {
    pictures.forEach((file) => {
      formData.append(`pictures`, file)
    })
  }
  if (logo) formData.append("logo", logo)
  return await networkService.axios
    .post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params: formData,
    })
    .catch((error) => {
      const err = error.response.data.message
      console.log(error)
      if (error.response.data.errorCode === "COINS_REACH_LIMIT") {
        throw new Error("COINS_REACH_LIMIT")
      }

      throw new Error(err)
    })
}

export default generateUsingText
