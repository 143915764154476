import { Tooltip, UploadFile } from "antd" // Assuming you're using Ant Design for Tooltip
import { Dropdown, Menu } from "antd" // Ant Design Dropdown and Menu components
import { FileUpload, Icons } from "@components/customized"

function AttachMediaButton({
  onUploadPictures: onUploadPictures,
  onUploadLogo,
  uploadedPictures,
  setUploadedPictures,
  uploadedLogo,
  setUploadedLogo,
}: {
  onUploadPictures: (file: UploadFile) => void
  onUploadLogo: (file: UploadFile) => void
  uploadedPictures: UploadFile[]
  setUploadedPictures: (pictures: UploadFile[]) => void
  uploadedLogo: UploadFile[]
  setUploadedLogo: (logo: UploadFile[]) => void
}) {
  const handlePictureChange = (info: any) => {
    onUploadPictures(info)
  }
  const handleLogoChange = (info: any) => {
    onUploadLogo(info)
  }
  const menu = (
    <Menu>
      <Menu.Item key="logo">
        <FileUpload
          title="Add Company Logo (PNG)"
          showUploadList={false}
          onUploadingFile={handleLogoChange}
          fileList={uploadedLogo}
          setFileList={setUploadedLogo}
          accept=".png"
        />
      </Menu.Item>
      <Menu.Item key="product">
        <FileUpload
          title="Add Product/Service Pictures"
          showUploadList={false}
          onUploadingFile={handlePictureChange}
          multiple={true}
          maxCount={3}
          fileList={uploadedPictures}
          setFileList={setUploadedPictures}
          accept="image/*"
        />
      </Menu.Item>
    </Menu>
  )

  return (
    <Tooltip title="Attach Media">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className={
          "mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-blue-500 p-2 text-white hover:bg-blue-600"
        }
      >
        <span className="flex items-center">
          <Icons.PaperClipOutlined size={40} />
        </span>
      </Dropdown>
    </Tooltip>
  )
}

export default AttachMediaButton
