import { ReactComponent as FacebookLogo1 } from "@assets/svg/facebook-logo.svg"
import { ReactComponent as Tiktok1 } from "@assets/svg/tiktok-logo.svg"
import { ReactComponent as Instagram1 } from "@assets/svg/instagram-logo.svg"
import { ReactComponent as LetstokBlack } from "@assets/svg/app-logo-black.svg"
import { ReactComponent as AILogo } from "@assets/svg/ai-logo.svg"

import {
  CheckOutlined,
  HomeOutlined,
  ThunderboltOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  DeploymentUnitOutlined,
  GlobalOutlined,
  InboxOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  UploadOutlined,
  MenuOutlined,
  UserOutlined,
  CloseOutlined,
  LoadingOutlined,
  ControlOutlined,
  FilterOutlined,
  AppstoreOutlined,
  StarOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  DownloadOutlined,
  SafetyOutlined,
  FormatPainterOutlined,
  LogoutOutlined,
  RightOutlined,
  LinkOutlined,
  DollarOutlined,
  CopyOutlined,
  EditOutlined,
  ExclamationOutlined,
  PlusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  FireOutlined,
  DeleteOutlined,
  FormOutlined,
  SwapOutlined,
  SendOutlined,
  PaperClipOutlined,
} from "@ant-design/icons"
import { Tools } from "@utils/types/common.types"

export const Icons = {
  LinkOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ExclamationOutlined,
  FireOutlined,
  FormatPainterOutlined,
  RightOutlined,
  SafetyOutlined,
  MinusOutlined,
  CheckOutlined,
  Home: HomeOutlined,
  EditOutlined,
  InboxOutlined,
  Generator: ThunderboltOutlined,
  NewOrder: VideoCameraAddOutlined,
  MyVideos: VideoCameraOutlined,
  Scaler: DeploymentUnitOutlined,
  Translator: GlobalOutlined,
  Pause: PauseOutlined,
  Play: PlayCircleOutlined,
  Upload: UploadOutlined,
  MenuHamburger: MenuOutlined,
  Close: CloseOutlined,
  User: UserOutlined,
  Logout: LogoutOutlined,
  Settings: SettingOutlined,
  Loading: LoadingOutlined,
  Controls: ControlOutlined,
  Sort: FilterOutlined,
  CopyOutlined: CopyOutlined,
  CardView: AppstoreOutlined,
  Dollar: DollarOutlined,
  Info: InfoCircleOutlined,
  Star: StarOutlined,
  Download: DownloadOutlined,
  FacebookLogo: FacebookLogo1,
  Tiktok: Tiktok1,
  Instagram: Instagram1,
  LetstokBlack,
  Delete: DeleteOutlined,
  DraftOrder: FormOutlined,
  SwapUsersOrder: SwapOutlined,
  Send: SendOutlined,
  AILogo,
  PaperClipOutlined,
}

export const ToolsIcons: Record<Tools, any> = {
  [Tools.Generator]: Icons.Generator,
  [Tools.Scaler]: Icons.Scaler,
  [Tools.Translator]: Icons.Translator,
  [Tools.Order]: Icons.NewOrder,
}
